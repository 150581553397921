import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuSidebarComponent } from './menu-sidebar/menu-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { OrdinalDatePipe } from '../services/common/ordinal-date.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FooterComponent, HeaderComponent, MenuSidebarComponent,OrdinalDatePipe],
  imports: [CommonModule, NgbModule, RouterModule,MatSelectModule,MatFormFieldModule,FormsModule,ReactiveFormsModule,TranslateModule],
  exports: [FooterComponent, HeaderComponent, MenuSidebarComponent,OrdinalDatePipe],
})
export class SharedModule {}
