import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HTTPListener, HTTPStatus } from "./interceptor/HTTPListener";
import { CommonModule, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";
import { RouteReuseService } from "./route-reuse/RouteReuseService";
import { MainComponent } from "./layout/main/main.component";
import { SharedModule } from "./component/shared.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AddEditRoleDialogComponent } from "./pages/role-management/addedit-role-dialog/addedit-role-dialog.component";
import { ConfirmationDialogComponent } from "./component/confirmation-dialog/confirmation-dialog.component";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ResetPassPageComponent } from "./pages/reset-pass/reset-pass-page.component";
import { MatCardModule } from "@angular/material/card";
import { DeleteConfirmationDialogComponent } from "./component/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { ActiveInactiveDialogComponent } from "./component/active-inactive-dialog/active-inactive-dialog.component";
import { MatSelectSearchModule } from "mat-select-search";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { DirtyComponent } from "./component/dirty/dirty.component";
import { OrdinalDatePipe } from "./services/common/ordinal-date.pipe";
import { ReportStatusComponent } from "./component/report-status/report-status.component";
import { UnderMainatanceComponent } from "./pages/under-mainatance/under-mainatance.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AddEditAssignmentComponent } from "./pages/assignment/add-edit-assignment/add-edit-assignment.component";
import { AddEditUserDialogComponent } from "./pages/user-management/add-edit-user-dialog/add-edit-user-dialog.component";
import { StudentClassListComponent } from './pages/student/student-class-list/student-class-list.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditExceptionReportReasonDialogComponent } from './pages/exception-report-dialogs/edit-exception-report-reason-dialog/edit-exception-report-reason-dialog.component';
const RxJS_Services = [HTTPListener, HTTPStatus];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ForgotPasswordComponent,
    AddEditRoleDialogComponent,
    ConfirmationDialogComponent,
    ResetPassPageComponent,
    DeleteConfirmationDialogComponent,
    ActiveInactiveDialogComponent,
    DirtyComponent,
    ReportStatusComponent,
    UnderMainatanceComponent,
    AddEditAssignmentComponent,
    AddEditUserDialogComponent,
    EditExceptionReportReasonDialogComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    ToastrModule.forRoot({ closeButton: true }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatSelectSearchModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MatDialogModule,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },

    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    DatePipe,
    OrdinalDatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
