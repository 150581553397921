<table class="float-end" aria-describedby="headerTable">
  <tr>
    <td *ngIf="isStudent">
      <mat-form-field class="me-1">
        <mat-select placeholder="Select language" [(ngModel)]="selectedLanguage" (selectionChange)="onLanguageChange()">
          <mat-select-trigger>
            <img *ngIf="selectedLanguage === 'en'" src="../../../assets/icons/united-kingdom.png" alt="English" width="20" height="20" class="me-2" />
            <img *ngIf="selectedLanguage === 'fr'" src="../../../assets/icons/france.png" alt="French" width="20" height="20" class="me-2" />
            {{ selectedLanguage === 'en' ? 'English' : 'French' }}
          </mat-select-trigger>
          <mat-option value="en">
            <img src="../../../assets/icons/united-kingdom.png" alt="English" width="20" height="20" class="me-2" /> English
          </mat-option>
          <mat-option value="fr">
            <img src="../../../assets/icons/france.png" alt="French" width="20" height="20" class="me-2" /> French
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
    <td>
      <div ngbDropdown class="d-inline-block">
        <span class="block-span hover-pointer p-0" ngbDropdownToggle>
          <img class="avatar avatar-sm rounded-circle" alt="Image placeholder" (error)="handleImageError()"
            [src]="UserImage ? UserImage : '../../../assets/img/default-user-profile.svg'" />
          <span id="customerNameContainer">
            <span class="username truncatedName" [title]="UserName">{{UserName}}</span>
          </span>
        </span>
        <div ngbDropdownMenu class="text-center p-0 mt-2">
          <span class="dropdown-menu-arrow"></span>
          <a class="d-flex align-items-center justify-content-between" (click)="NavigateToProfile()" ngbDropdownItem>
            <span><img src="../../../assets/icons/my-account.svg" class="me-2" alt="">{{LabelConstants.MyAccount}}</span>
            <img src="../../../assets/icons/dropdown-arrow-grey.svg" class="ms-2" alt="">
          </a>
          <a class="d-flex align-items-center justify-content-between" type="button" (click)="LogOut('Logout')">
            <span><img src="../../../assets/icons/logout-2.svg" class="me-2" alt="">{{LabelConstants.Logout}}</span>
            <img src="../../../assets/icons/dropdown-arrow-grey.svg" class="ms-2" alt="">
          </a>
        </div>
      </div>
    </td>
  </tr>
</table>
<a id="btn-toggle" href="javascript:void(0)" class="sidebar-toggler break-point-lg start-0 pt-1">
  <i class="ri-menu-line ri-xl"></i>
</a>
