<!-- If Logged in as admin -->
<aside id="sidebar" class="sidebar break-point-lg has-bg-image" *ngIf="isAdmin">
  <a id="btn-collapse" class="sidebar-collapser"><i class="fa-solid fa-arrow-left"></i></a>
  <div class="sidebar-layout">
    <div class="sidebar-header">
      <div class="small-logo cursor-pointer btn-toggle-dashboard"  routerLink="/dashboard">
        <img src="../../../assets/img/small-logo.svg" alt="">
     </div>
      <div class="pro-sidebar-logo cursor-pointer btn-toggle-dashboard" routerLink="/dashboard">
         <img src="../../../assets/img/sidebar-logo.svg" alt="">
      </div>
    </div>
    <div class="sidebar-content">
      <nav class="menu open-current-submenu" id="myNav">
        <ul>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/dashboard" [routerLinkActiveOptions]="{exact: true}" >
              <span class="menu-icon">
                 <img src="../../../assets/icons/dashboard.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.dashboard}}</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/role">
              <span class="menu-icon">
                <img src="../../../assets/icons/role.svg" alt="">
              </span>
              <span class="menu-title">Role Management</span>
            </a>
          </li>
           <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active"  routerLink="/class">
              <span class="menu-icon">
                <img src="../../../assets/icons/session.svg" alt="">
              </span>
              <span class="menu-title">Class</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/classes" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/form-builder.svg" alt="">
              </span>
              <span class="menu-title">Assignment</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/user" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/user-management.svg" alt="">
              </span>
              <span class="menu-title">User Management</span>
            </a>
          </li> 
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/student" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/user.svg" alt="">
              </span>
              <span class="menu-title">Student Management</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/parents" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/user-management.svg" alt="">
              </span>
              <span class="menu-title">Parents Management</span>
            </a>
          </li> 
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/teacher" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/user.svg" alt="">
              </span>
              <span class="menu-title">Teacher Management</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/plan" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/coupon.svg" alt="">
              </span>
              <span class="menu-title">Plan Management</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/feedback/admin" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/appoinment.svg" alt="">
              </span>
              <span class="menu-title">Feedback Admin</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/report" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/report.svg" alt="">
              </span>
              <span class="menu-title">Report</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/exception-report" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/appoinment.svg" alt="">
              </span>
              <span class="menu-title">Exception Report</span>
            </a>
          </li>
          <li class="menu-item logout-menu">
            <a href="javascript:void(0)" (click)="LogOut('Logout')" >
              <span class="menu-icon">
                <img src="../../../assets/icons/logout.svg" alt="">
              </span>
              <span class="menu-title text-white">{{LabelConstant.logout}}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</aside>

<!-- If Logged in as teacher -->
<aside id="sidebar" class="sidebar break-point-lg has-bg-image" *ngIf="isTeacher">
  <a id="btn-collapse" class="sidebar-collapser"><i class="fa-solid fa-arrow-left"></i></a>
  <div class="sidebar-layout">
    <div class="sidebar-header">
      <div class="small-logo cursor-pointer btn-toggle-dashboard"  routerLink="/dashboard">
        <img src="../../../assets/img/small-logo.svg" alt="">
     </div>
      <div class="pro-sidebar-logo cursor-pointer btn-toggle-dashboard" routerLink="/dashboard">
         <img src="../../../assets/img/sidebar-logo.svg" alt="">
      </div>
    </div>
    <div class="sidebar-content">
      <nav class="menu open-current-submenu" id="myNav">
        <ul>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/dashboard" [routerLinkActiveOptions]="{exact: true}" >
              <span class="menu-icon">
                 <img src="../../../assets/icons/dashboard.svg" alt="">
              </span>
              <span class="menu-title">{{LabelConstant.dashboard}}</span>
            </a>
          </li>
           <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active"  routerLink="/class-session">
              <span class="menu-icon">
                <img src="../../../assets/icons/session.svg" alt="">
              </span>
              <span class="menu-title">Class</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/classes" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/form-builder.svg" alt="">
              </span>
              <span class="menu-title">Assignment</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/plan" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/coupon.svg" alt="">
              </span>
              <span class="menu-title">Plan Management</span>
            </a>
          </li>
          <!-- <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/student" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/report.svg" alt="">
              </span>
              <span class="menu-title">Student Management</span>
            </a>
          </li> -->
          <!-- <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/exception-report" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/appoinment.svg" alt="">
              </span>
              <span class="menu-title">Exception Report</span>
            </a>
          </li> -->
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/feedback" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/appoinment.svg" alt="">
              </span>
              <span class="menu-title">Feedback</span>
            </a>
          </li>
          <li class="menu-item logout-menu">
            <a href="javascript:void(0)" (click)="LogOut('Logout')" >
              <span class="menu-icon">
                <img src="../../../assets/icons/logout.svg" alt="">
              </span>
              <span class="menu-title text-white">{{LabelConstant.logout}}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</aside>

<!-- If Logged in as student -->
<aside id="sidebar" class="sidebar break-point-lg has-bg-image" *ngIf="isStudent">
  <a id="btn-collapse" class="sidebar-collapser"><i class="fa-solid fa-arrow-left"></i></a>
  <div class="sidebar-layout">
    <div class="sidebar-header">
      <div class="small-logo cursor-pointer btn-toggle-dashboard" routerLink="/dashboard">
        <img src="../../../assets/img/small-logo.svg" alt="">
      </div>
      <div class="pro-sidebar-logo cursor-pointer btn-toggle-dashboard" routerLink="/dashboard">
        <img src="../../../assets/img/sidebar-logo.svg" alt="">
      </div>
    </div>
    <div class="sidebar-content">
      <nav class="menu open-current-submenu" id="myNav">
        <ul>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/dashboard" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/dashboard.svg" alt="">
              </span>
              <span class="menu-title">{{ 'sidebar.dashboard' | translate }}</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/student-class">
              <span class="menu-icon">
                <img src="../../../assets/icons/session.svg" alt="">
              </span>
              <span class="menu-title">{{ 'sidebar.classes' | translate }}</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/classes" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/form-builder.svg" alt="">
              </span>
              <span class="menu-title">{{ 'sidebar.assignment' | translate }}</span>
            </a>
          </li>
          <li class="menu-item">
            <a href="javascript:void(0)" class="btn-toggle-dashboard" routerLinkActive="active" routerLink="/feedback" [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-icon">
                <img src="../../../assets/icons/appoinment.svg" alt="">
              </span>
              <span class="menu-title">{{ 'sidebar.feedback' | translate }}</span>
            </a>
          </li>
          <li class="menu-item logout-menu">
            <a href="javascript:void(0)" (click)="LogOut('Logout')">
              <span class="menu-icon">
                <img src="../../../assets/icons/logout.svg" alt="">
              </span>
              <span class="menu-title text-white">{{ 'sidebar.logout' | translate }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</aside>

<div id="overlay" class="overlay"></div>
