import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlHelper {
  public apiUrl = {
    Login: {
      UserLogin: '/general/login',
      logout: '/general/logout'
    },
    Register:{
      RegisterUser: 'admin/user/saveby-user'
    },
    Role:{
      GetRoleList: '/admin/role/role-list',
      GetRoleById: 'admin/role/getrole/{Id}',
      SaveUpdateRole: 'admin/role/save',
      DeleteRole: 'admin/role/delete/{Id}',
      GetRole: 'admin/role/role-list-drop-down'
    },
    User:{
      GetUserList: '/admin/user/list',
      GetUserById: '/admin/user/get-user/{Id}',
      DeleteUser: '/admin/user/delete/{Id}',
      UserActiveDeactive: '/admin/user/active-inactive/{Id}',
      UserDropDownList: '/admin/user/user-dropdown-list',
      AddUpdateUser: '/admin/user/useradd-admin',
      SaveUserBasicDetails:'/admin/user/save-admin-detail',
      GenerateFormReportpdf:'/admin/user/form-report-pdf',
      GetStudentListAsPerClassId:''
    },
    Country:{
      GetCountryList:'/general/country-list'
    },
    State:{
      GetStateList:'/general/state-list/{Id}'
    },
    city:{
      GetCityList: '/general/city-list/{Id}'
    },
    Customer:{
      GetCustomerList:'admin/customer/list',
      GetCustomerById: 'admin/customer/get-customer-admin/{Id}',
      DeleteCustomer: 'admin/customer/delete/{Id}',
      AddUpdateCustomer: 'admin/customer/customeradd-admin',
      CustomerActiveDeactive: 'admin/customer/active-inactive/{Id}'
    },
    CustomerActivities:{
      GetCustomerSessionList:'admin/customer/booked-session-list',
      GetCustomerEventList:'admin/customer/booked-event-list',
      GetCustomerReportRefundList:'admin/report/customer-report-list',
    },
    Payment:{
      GetPaymentTypeList: 'admin/payment-pass/list',
      GetPaymentTypeByID: 'admin/payment-pass/getpayment-pass/{Id}',
      SaveUpdatePaymentType: 'admin/payment-pass/save',
      DeletePaymentType: 'admin/payment-pass/delete/{Id}',
      PassTypeList: 'admin/setting/social-media/list/{Id}',
      GetPassTransactionDetails:'admin/payment-pass/get-pass-transaction-details',
      GetPassList: 'admin/payment-pass/get-pass-list/{Id}',
    },
    MyAccount:{
      UpdateUser: 'admin/user/updateby-user'
    },
    Class:{
      GetClassList: 'admin/class/list',
      GetClassById: 'admin/class/class-details/{Id}',
      GetSessionListByClassId: 'admin/class/session-list',
      GetSessionAttendanceList: '/admin/class/session-attendance-list/{Id}',
      GetSessionCustomerList: 'admin/class/session-details',
      DeleteClass: 'admin/class/delete-class/{Id}',
      DeleteSession: '/admin/class/delete-session/{Id}',
      SaveClass: 'admin/class/save',
      GetClassConfirmation : 'admin/class/check-session-conflict',
      SaveSessionAttendance : '/admin/class/save-session-attendance',
      GetUpcomingSessionForViewMore: 'admin/class/upcoming-list-by-day',
      GetUpcomingSessionForCustomer: 'admin/class/upcoming-list',
      SaveMultipleStudent:'/teacher/add-new-student-detail'
    },
    Yoga: {
      GetSessionListByHost: 'admin/class/session-list-by-hostId',
      GetSessionAttendanceListByHost: 'admin/class/session-attendance-list/{Id}',
      GetEventListByHost: 'admin/event/event-list-by-hostId',
      GetEventAttendanceList: '/admin/class/session-attendance-list/{Id}',
    },
    Orders : {
      GetOrderList : 'admin/order/list',
      OrderPreview : 'admin/order/details/{Id}',
      OrderEventQuestion : 'admin/order/get-question-list',
      CustomerList:'admin/customer/dropdown-list',
      GetAddOrderPassList: 'admin/order/payment-type-list',
      AddOrder: 'admin/order/save',
      EarlyCancel : 'customer/order/early-cancel-session'
    },
    ChangePass:{
      ChangePassword:  '/general/change-password'
    },
    Coupon:{
      InsertUpdateCoupon: 'admin/coupon/save',
      GetCouponList: 'admin/coupon/list',
      GetCouponById: 'admin/coupon/getcoupon/{Id}',
      DeleteCoupon: 'admin/coupon/delete/{Id}',
      GetAllCustomerByCoupon: 'admin/coupon/get-couponcode-detail',
      ActiveInActiveCouponCode : 'admin/coupon/active-inactive/{Id}',
      UsedCouponByCustomer : 'admin/coupon/used-coupon-code-details/{Id}' ,
      ApplyCouponCode: 'admin/order/apply-coupon-code'
    },
    ForgotPass:{
      ForgotSendEmail:'/general/forgot-password',
      ResetPassword:'/general/success-forgot-password'
    },
    Question:{
      InsertUpdateQuestion: 'admin/question/save',
      GetQuestionList: 'admin/question/list',
      GetQuestionById: 'admin/question/get-question/{Id}',
      DeleteQuestion: 'admin/question/delete/{Id}',
      GetQuestionTypeList: 'admin/question/question-type-list'
    },
    Event:{
      GetUserList: 'admin/user/user-dropdown-list',
      GetQuestionAnswerList: 'admin/question/question-dropdown-list',
      AddUpdateEvent: 'admin/event/save',
      AddUpdateConfirmationEvent: 'admin/event/check-event-conflict',
      GetEventList: 'admin/event/event-list', 
      GetEventCustomerList: 'admin/event/get-event-customerdetail', 
      GetEventById: 'admin/event/event-detail/{Id}',
      DeleteEvent: 'admin/event/delete-event',
      GetQuestionOptions: 'admin/event/event-question-list/{Id}',
      DeleteGalleryImage: 'admin/event/delete-event-gallery-image/{Id}',
      DeleteQuestion: 'admin/event/delete-event-question/{Id}',
      EventAttendance: 'admin/event/event-attendance-list/{Id}',
      SaveAttendance :'admin/event/save-event-attendance',
      GetEventListForCustomer: 'admin/event/event-list-by-customer',
    },
    Appointment:{
      AddUpdateAppointmentByAdmin:'admin/appointment/save',
      AddUpdateAppointmentByCustomer:'customer/appointment/save',
      DeleteAppointment:'',
      GetAppointmentDropdownList:'customer/appointment/appointment-type-drop-down',
      GetAppointmentListForAdmin: 'admin/appointment/appointment-list',
      GetAppointmentByIdForAdmin:'admin/appointment/get-appointment-detail/{Id}', 
      GetAppointmentByIdForCustomer:'',
      GetAppointmentListForCustomer: '',         
    },
    Settings:{
      AddPrivacyPolicy:'admin/setting/privacy-policy/save',
      GetPrivacyPolicy:'admin/setting/privacy-policy/page',
      AddTermsAndConditions: 'admin/setting/terms-condition/save',
      GetTermsAndConditions: 'admin/setting/terms-condition/page',
      AddtestiMonial:'admin/setting/testimonial-review/save',
      GetTestiMonialById: 'admin/setting/testimonial-review/{Id}',
      GetTestiMonialList: 'admin/setting/testimonial-review/list',
      DeleteTestimonialById: 'admin/setting/testimonial-review/delete/{Id}',
      AddSocialMedia: 'admin/setting/social-media/save',
      GetSocialMedia: 'admin/setting/social-media/list/{settingType}',
      AddAboutImages:'admin/setting/about-us/save',
      GetAboutImageById: 'admin/setting/about-us/about-us-image/{Id}',
      GetAboutImageList:'admin/setting/about-us/about-us-image-list',
      DeleteAboutImage: 'admin/setting/about-us/delete/{Id}',
      AddAboutSection:'admin/setting/about-us/save-about-us-section',
      GetAboutAlignmentDropdown: 'admin/setting/about-us/page-section-layout-type-dropdown-list',
      GetAboutSectionList:'admin/setting/about-us/list',
      DeleteAboutSection: 'admin/setting/about-us/delete-about-us-section/{Id}',
      GetAboutSectionById: 'admin/setting/about-us/get-about-us-section/{Id}',
      DeleteAboutSectionImage:'admin/setting/about-us/delete-about-us-section-image/{Id}'
    },
    Dashboard:{
      dashboardInfo : 'dashboard/info'
    },
    RewardCoupon:{
      CustomerList:'admin/reward-coupon-code/customer-list',
      SubmitReward: 'admin/reward-coupon-code/save',
      GetRewardCouponList: 'admin/reward-coupon-code/reward-list'
    },
    Report: {
      reportList:'admin/report/list',
      reportDetail : 'admin/report/getdetail',
      approveRejectReport : 'admin/report/approve-reject',
      refundSave: 'admin/report/refund-save',
      AddExtraSession: 'admin/report/add-extra-session',
      GetStudentListClassWise:'/student/get-student-list/{Id}',
      GenerateAcademicReport:'/student/generate-report-student',
      GenerateMergeReport:'/student/generate-merge-report-student/{Id}'
    },
    Stripe:{
      refundInitiate: 'admin/stripe/initiate-refund'
    },
    SocialMediaLinks: {
      GetSocialMediaLinks: 'customer/setting/social-media/list/{Id}'
    },
    General:{
      GetRoleList:'/general/role/role-list-drop-down',
      ProgressList:'/general/progress-drop-down',
      BehaviourList:'/general/behaviour-drop-down'
    },
    Assignment:{
      GetAssignmentList:'/admin/assignment/detail-list',
      SaveAssignment:'/admin/assignment/save',
      DeleteAssignment:'/admin/assignment/delete/{Id}',
      GetClassList:'/admin/assignment/list',
      GetAssignmentDetail:'/admin/assignment/get-detail/{Id}',
      SendAssignment:'/admin/assignment/send/{Id}'
    },
    DropDownList:
    {
       GetClassDropDownList:"/general/class-drop-down/{Id}",
       GetPaymentTypeDropDownList:"/general/payment-type-drop-down",
    },
    InoKingsClass:{
      GetClassList:'/admin/class/list',
      GetClassListForReport:'/admin/class/report-class-list',
      SaveClass:'/admin/class/save',
      ClassById:'/admin/class/class-details/{Id}',
      DeleteClass:'/admin/class/delete-class/{Id}',
      GetTeacherDropdownList:'/admin/user/user-dropdown-list',
      GetClassTypeList:'/admin/class/class-type-drop-down',
      GetOutdoorClassList:'/admin/class/outside-school-drop-down',
      GetSessionList:'/admin/class/session-list',
      DeleteSession:'/admin/class/delete-session/{Id}',
      GetSessionByHostId:'/admin/class/session-list-by-hostId',
      GetStudentListAsPerSession:'/admin/class/session-details',
      GetClassSessionTeacherSummaryComment:'/general/comment-summary-session/{Id}',
      SaveSummaryCommentOfClassSession:'/general/save-summary-comment',
       GetSessionListByHost: '/admin/class/session-list-by-hostId'
    },
    Student:{
      SubmitStudentBasicDetails:'/admin/student/save-basic-detail',
      SubmitStudentClassHistory:'/admin/student/assign-class',
      IsWantToDiscontinueClass:'/admin/student/is-want-discontinue/{Id}',
      IsWantToJoinNewClass:'/admin/student/is-want-join-new/{Id}',
      IsAcademinStatusCompleted:'/admin/student/is-complete-academic/{Id}',
      GetStudentDetail:'/admin/user/get-student-detail/{Id}',
      GetStudentBehaviourProgressDetail:'/general/behaviour-progress-student/{Id}',
      SaveProgressBehaviour:'/general/save-progress-behaviour',
      GetStudentListByClassId:'/student/get-student-list',
      GetStudentClass:'/student/get-student-class',
      GetStudentList:'/admin/student/list',
      GetParentList:'/general/parent-lists',
      GetStudentDetailById:'/admin/student/get-student-detail/{Id}',
      SaveStudent:'/admin/student/save-basic-detail'
    },
    Teacher:{
      SubmitTeacherBasicDetails:'/admin/teacher/save'
    },
    Feedback:{
      GetFeedbackById:'/feedback/getdetail/{Id}',
      SaveFeedback:'/feedback/save-feedback',
      DeleteFeedback:'/feedback/delete/{Id}',
      FeedbackList:'/feedback/list',
      SaveFeedbackByAdmin:'/admin/feedback/feedback-resolve',
      SessionDropdownByClassId:'/general/feedback/session-date-drop-down/{Id}',
      GetFeedBackType:"/general/feedback-type"
    },
    InokingsDashboard:{
      GetStudentDashboardDetails:'/student/dashboard/{Id}',
      GetTeacherDashboardDetails:'/teacher/dashboard',
      GetAdminDashboardDetails:'/general/user/dashboard'
    },
    ParentManagement:{
      AddMultipleStudents:'/admin/student/add-child-detail',
      GetStudentListByParentId:'/general/child-name'
    },
    PlanManagement:{
      PlanList:'/admin/plan/list',
      GetPlanById:'/admin/plan/get-detail/{Id}',
      AddEditPlan:'/admin/plan/save',
      DeletePlan:'/admin/plan/delete/{Id}',
      PublishPlan:'/admin/plan/publish/{Id}',
      PlanDetailList:'/admin/plan/detail-list',
      GetSessionName:'/general/session-name/{Id}'

    },
    StudentUpdatedManagement:{
      GetStudentBasicDetail:'/admin/student/get-student-detail/{Id}',
      ActiveInactiveStudent:'/admin/student/active-inactive/{Id}',
      DeleteStudent:'/admin/student/delete/{Id}'
    },
    ExceptionalReport:{
      GetExceptionalStudentList:'/general/student-name-for-exceptional-report',
      SaveExceptionStudent:'/student/save-student-exceptional-report',
      GetExceptionalReportList:'/student/get-exceptional-report-list',
      DeleteExceptionalStudent:'/student/delete/exceptional-report/{Id}',
      UpdateExceptionalReason:'/student/update-exceptional-report-reason'
    }


  };

}
